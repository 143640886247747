@let _cursor = cursor();

<app-cursor-item-base
  [cursor]="cursor()"
  #itemBase
  (updated)="updated.emit($event)"
  (deleted)="deleted.emit()"
  (opened)="opened.emit()"
  (closed)="closed.emit()">
  <div cursor-description class="self-center">
    Offset: {{ _cursor.offset | displayNumber: 'scientific' : 6 : 1e-12 }} ; Interval:
    {{ _cursor.interval | displayNumber: 'scientific' : 6 : 1e-12 }}
  </div>
  <div cursor-form>
    <mat-form-field>
      <mat-label>Offset</mat-label>
      <input type="number" matInput [ngModel]="_cursor.offset" (ngModelChange)="updateOffset($event)" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Interval</mat-label>
      <input type="number" matInput [ngModel]="_cursor.interval" (ngModelChange)="updateInterval($event)" />
    </mat-form-field>
  </div>
</app-cursor-item-base>
