import { Component, input, output, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DisplayNumberPipe, PositiveHarmonicsMarker } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { CursorItemBaseComponent } from '../cursor-item-base/cursor-item-base.component';

@Component({
  selector: 'app-harmonics-cursor-item',
  imports: [MaterialModule, FormsModule, DisplayNumberPipe, CursorItemBaseComponent],
  templateUrl: './harmonics-cursor-item.component.html',
})
export class HarmonicsCursorItemComponent {
  cursor = input.required<PositiveHarmonicsMarker>();

  updated = output<PositiveHarmonicsMarker>();
  deleted = output<void>();
  opened = output<void>();
  closed = output<void>();

  itemBase = viewChild.required<unknown, CursorItemBaseComponent<PositiveHarmonicsMarker>>('itemBase', {
    read: CursorItemBaseComponent<PositiveHarmonicsMarker>,
  });

  updateInterval = (interval: number) => this.itemBase().updateCursor(cursor => ({ ...cursor, interval }));
}
