@if (color(); as color) {
  <button
    [style.backgroundColor]="color"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    class="mr-1 inline-block h-[12px] w-[12px] rounded-sm ring-gray-500 hover:ring-2"
    (click)="onClick($event)"></button>
  <mat-menu #menu="matMenu" class="overflow-clip">
    <div
      class="m-0 p-0"
      [colorPicker]="color"
      [cpPresetColors]="presetColors"
      cpPosition="auto"
      [cpPositionRelativeToArrow]="false"
      [cpToggle]="true"
      cpAlphaChannel="disabled"
      [cpOKButton]="true"
      [cpCancelButton]="true"
      cpDialogDisplay="inline"
      (colorPickerChange)="onChange($event)"
      (colorPickerCancel)="menuTrigger.closeMenu()"
      (colorPickerSelect)="onChange($event); menuTrigger.closeMenu()"></div>
  </mat-menu>
}
