@let _filename = filename();
@let _fusion = fusion();
@let _harmonicSeries = harmonicSeries();
@let _cycles = cycles();
@let cyclesReady = ready(_cycles);
@let fusionReady = ready(_fusion);
@let harmonicSeriesReady = ready(_harmonicSeries);
@let nCycles = cyclesReady ? _cycles.data!.length : 0;

<div class="flex w-full flex-col gap-8">
  <div class="flex flex-initial flex-col items-center">
    @if (cyclesReady) {
      <app-report-sub-section [subSectionData]="spectrumsSubSection" class="w-full">
        <app-cycles-selection
          [cycles]="cycles().data!"
          #cyclesSelection
          section-header
          class="ml-6 flex justify-start" />
        <app-cycles
          [filename]="_filename"
          [cycles]="cycles().data!"
          [activeCycleIndex]="cyclesSelection.activeCycleIndex()"
          [showingAll]="cyclesSelection.showingAll()"
          class="w-full" />
      </app-report-sub-section>
    } @else {
      <app-result-unavailable [loadableData]="cycles()" class="h-32 w-full" />
    }
  </div>
  @if (nCycles > 1) {
    <div class="flex flex-initial flex-col items-center">
      @if (fusionReady && harmonicSeriesReady) {
        <app-report-sub-section [subSectionData]="peaksAndHarmonicsSubSection()" class="w-full">
          @if (hasHarmonics()) {
            <app-peaks-harmonics-selection
              [selectedMode]="selectedMode()"
              (selectedModeChange)="selectedMode.set($event)"
              section-header
              class="ml-6 mt-[-2px] flex justify-start" />
          }
          <app-peaks-and-harmonic-series
            [filename]="_filename"
            [harmonicSeries]="_harmonicSeries.data!"
            [fusion]="_fusion.data!"
            [selectedMode]="selectedMode()"
            class="w-[95%]" />
        </app-report-sub-section>
      } @else {
        <app-result-unavailable [loadableData]="fusionReady ? _harmonicSeries : _fusion" class="h-32 w-full" />
      }
    </div>
  }
</div>
