import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ComputationStatus } from '@shared/interfaces/processing-status';

import { HarmonicTrajectory, SensorTrajectories, Trajectory } from '../interfaces/trajectory.interface';
import { TrendType } from '../interfaces/trajectory-trend.interface';

export const SensorTrajectoriesActions = createActionGroup({
  source: 'SensorTrajectories',
  events: {
    trajectoriesFetchRequested: props<{ sensorId: SensorId }>(),
    trajectoriesFetchFailed: props<{ sensorId: SensorId }>(),
    trajectoriesNotFound: props<{ sensorId: SensorId }>(),
    trajectoriesFetched: props<{ sensorId: SensorId; sensorTrajectories: SensorTrajectories }>(),

    trajectoriesStatusChanged: props<{
      sensorId: SensorId;
      computationStatus: ComputationStatus;
      computationMessage: string | undefined;
      nextComputationDate: Date | null;
    }>(),

    trajectoriesTrendVisualizeRequested: props<{ sensorId: SensorId; trajectory: Trajectory; trendType: TrendType }>(),
    harmonicTrendVisualizationRequested: props<{
      sensorId: SensorId;
      trajectory: HarmonicTrajectory;
      trendType: TrendType;
    }>(),

    trajectoriesTrendFetchFailed: emptyProps(),
  },
});
