@let _statusItemDescription = statusItemDescription();
@let _columns = columns();
@let _filters = filters();
@let _filterValues = filtersValues();
@let _trendsSelectionState = trendsSelectionState();

<div class="flex h-full w-full flex-col">
  <div class="mb-1 flex w-full flex-row items-center align-baseline">
    <div class="m3-title-large">Sensor Trajectories</div>

    <button mat-icon-button matTooltip="Refresh trajectories" (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>

    @if (hasTrajectoryTypeChoice()) {
      <mat-radio-group
        aria-label="Trajectory type"
        class="mx-4"
        [value]="trajectoryType()"
        name="trajectory-type"
        (change)="setTrajectoryType($event)">
        <mat-radio-button [value]="TrajectoryType.PeakTrajectory"> Peak </mat-radio-button>
        <mat-radio-button [value]="TrajectoryType.HarmonicSeriesTrajectory"> Harmonics </mat-radio-button>
      </mat-radio-group>
    }

    <button
      mat-flat-button
      class="ml-auto"
      data-testid="visualize-selected"
      matTooltip="Show selected data in graph"
      [disabled]="!hasSelected()"
      (click)="visualize()">
      <mat-icon>insights</mat-icon>Visualize
    </button>
  </div>

  @if (dataSource(); as dataSource) {
    <app-scrollable-bordered-container class="flex-grow select-none" [fadeTop]="true" [fadeBottom]="true">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="detectionPercentage"
        matSortDirection="desc"
        class="m3-body-medium">
        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            data-testid="name-column"
            class="border-outline-variant grow-0 basis-48 rounded-tl-lg">
            <div mat-sort-header>Trajectory</div>
            <app-option-filter
              [values]="nameOptions"
              [inputFilter]="_filters?.nameFilter"
              (outputFilter)="filterName($event)" />
          </mat-header-cell>
          <mat-cell *matCellDef="let trajectory" class="fg-on-surface-variant border-outline-variant grow-0 basis-48">{{
            trajectory.name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="detectionPercentage">
          <mat-header-cell *matHeaderCellDef class="border-outline-variant grow-0 basis-48">
            <div mat-sort-header>Detection ratio</div>
            <app-filter
              [values]="_filterValues?.get('detectionPercentage') ?? []"
              [inputFilter]="_filters?.detectionPercentageFilter"
              (outputFilter)="filterDetectionPercentage($event)" />
          </mat-header-cell>
          <mat-cell *matCellDef="let trajectory" class="fg-on-surface-variant border-outline-variant grow-0 basis-48">
            {{ trajectory.detectionPercentage | number: '2.2-2' }}%
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="frequency">
          <mat-header-cell *matHeaderCellDef class="border-outline-variant grow-0 basis-48">
            <div mat-sort-header>Frequency</div>
            <app-filter
              data-testid="filter-frequency"
              [values]="_filterValues?.get('frequency') ?? []"
              [inputFilter]="_filters?.frequencyFilter"
              (outputFilter)="filterFrequency($event)" />
          </mat-header-cell>
          <mat-cell *matCellDef="let trajectory" class="fg-on-surface-variant border-outline-variant grow-0 basis-48">
            {{ trajectory.frequency | number: '1.0-3' }} Hz
          </mat-cell>
        </ng-container>

        <ng-template
          #trendColumnDef
          let-column="column"
          let-trendType="trendType"
          let-colorFilter="colorFilter"
          let-columnName="columnName"
          let-tooltip="tooltip"
          let-isCompound="isCompound">
          @let compoundColumn = isCompound ?? false;

          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef class="border-outline-variant grow-0 basis-48 justify-center">
              @if (isTrendColumnSelectable(trendType)) {
                <mat-checkbox
                  [checked]="_trendsSelectionState?.get(trendType)?.all ?? false"
                  [indeterminate]="_trendsSelectionState?.get(trendType)?.partial ?? false"
                  (change)="trendTypeCheckboxChange(trendType, $event.checked)" />
              }
              @if (compoundColumn) {
                <span
                  class="-mr-4 -mt-2 whitespace-nowrap"
                  [matTooltip]="compoundFormattedTooltip(tooltip)"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="400"
                  [matTooltipHideDelay]="400"
                  matTooltipClass="whitespace-pre-wrap"
                  >{{ columnName }}
                  <mat-icon class="scale-75">info</mat-icon>
                </span>
              } @else {
                <span
                  class="whitespace-nowrap"
                  [matTooltip]="tooltip"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="400"
                  [matTooltipHideDelay]="400"
                  >{{ columnName }}</span
                >
              }
            </mat-header-cell>
            <mat-cell
              *matCellDef="let trajectory"
              class="fg-on-surface-variant border-outline-variant grow-0 basis-48 justify-center p-1">
              <app-trend-miniature
                [class]="isVisualized(trajectory, trendType) ? '' : 'hover:cursor-pointer'"
                (click)="toggle(trendType, trajectory)"
                [selected]="isSelected(trendType, trajectory.id)"
                [checked]="isVisualized(trajectory, trendType)"
                [attr.data-testid]="'visualize-' + trendType + '-' + trajectory.id"
                [contentPath]="getTrendContentPath(trajectory, trendType)"
                [colorFilter]="colorFilter" />
            </mat-cell>
          </ng-container>
        </ng-template>

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: {
              column: 'frequencyMiniature',
              trendType: TrendType.Frequency,
              columnName: 'Frequency',
              colorFilter: 'purple-filter',
            }
          " />

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: {
              column: 'energyMiniature',
              trendType: TrendType.Energy,
              columnName: 'Energy',
              colorFilter: 'green-filter',
            }
          " />

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: {
              column: 'harmonicFrequenciesMiniature',
              trendType: TrendType.HarmonicFrequencies,
              columnName: 'H. frequencies',
              tooltip: 'Harmonic frequencies',
              colorFilter: 'red-filter',
              isCompound: true,
            }
          " />

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: {
              column: 'harmonicEnergiesMiniature',
              trendType: TrendType.HarmonicEnergies,
              columnName: 'H. energies',
              tooltip: 'Harmonic energies',
              colorFilter: 'yellow-filter',
              isCompound: true,
            }
          " />

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: {
              column: 'harmonicsCountMiniature',
              trendType: TrendType.HarmonicsCount,
              columnName: 'H. count',
              tooltip: 'Harmonics count',
              colorFilter: 'orange-filter',
            }
          " />

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: {
              column: 'averageHarmonicEnergyMiniature',
              trendType: TrendType.AverageHarmonicEnergy,
              columnName: 'Average h. energy',
              tooltip: 'Average harmonic energy',
              colorFilter: 'blue-filter',
            }
          " />

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: {
              column: 'regularityMiniature',
              trendType: TrendType.Regularity,
              columnName: 'Regularity',
              colorFilter: 'magenta-filter',
            }
          " />

        <ng-container
          *ngTemplateOutlet="
            trendColumnDef;
            context: {
              column: 'thdMiniature',
              trendType: TrendType.THD,
              columnName: 'THD',
              colorFilter: 'cyan-filter',
            }
          " />

        <ng-container matColumnDef="filler">
          <mat-header-cell *matHeaderCellDef class="border-outline-variant flex-grow rounded-tr-lg" />
          <mat-cell *matCellDef="let trajectory" class="fg-on-surface-variant border-outline-variant flex-grow" />
        </ng-container>

        <mat-header-row *matHeaderRowDef="_columns; sticky: true" class="bg-scheme-surface" />
        <mat-row class="bg-white" *matRowDef="let trajectory; columns: _columns" />

        <div *matNoDataRow class="flex flex-row justify-center">
          <span>No trajectory found</span>
        </div>
      </mat-table>
    </app-scrollable-bordered-container>
    <div class="relative flex flex-row">
      <div class="flex flex-1">
        <mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" showFirstLastButtons pageSize="50" />
      </div>

      <div class="flex flex-row items-center">
        <span>Computation: </span>
        <button
          mat-icon-button
          matTooltip="{{ _statusItemDescription.tooltip }}{{
            sensorTrajectoriesNextComputationDate() | date: ' (HH:mm)'
          }}">
          <mat-icon
            class="text-center"
            [ngStyle]="{ color: _statusItemDescription.color }"
            [ngClass]="_statusItemDescription.itemClass">
            {{ _statusItemDescription.icon }}
          </mat-icon>
        </button>
        @if (canRecompute()) {
          <button matTooltip="Recompute" mat-icon-button (click)="recompute()">
            <mat-icon>play_circle</mat-icon>
          </button>
        }
      </div>

      <div class="flex-1"></div>
    </div>
  } @else {
    <span>Trajectories not computed for this sensor</span>
  }
</div>
