import {
  //AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  OnInit,
  signal,
  Signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { PathBarComponent } from '@components/path-bar/path-bar.component';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { ProjectRouter } from '@features/auth/shared/services/projectRouter';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { EquipmentsActions } from '@features/equipments/shared/store/equipments.actions';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import {
  ReportStepperComponent,
  Step,
} from '@features/signal-report/components/report-stepper/report-stepper.component';
import { SignalReportActions } from '@features/signal-report/shared/store/signal-report.actions';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { signalsFeature } from '@features/signals/shared/store/signals.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';
import { ReportDataValidationComponent } from '../../components/report-data-validation/report-data-validation.component';
import { ReportOverviewComponent } from '../../components/report-overview/report-overview.component';
import { ReportSpectralAnalysisComponent } from '../../components/report-spectral-analysis/report-spectral-analysis.component';
import { ReportSectionComponent } from '../../components/report-section/report-section.component';
import {
  DATA_VALIDATION_SECTION,
  OVERVIEW_SECTION,
  REPORT_STRUCTURE,
  SPECTRAL_ANALYSIS_SECTION,
} from '@features/signal-report/shared/interface/report-structure';

@Component({
  selector: 'app-one-page-signal-report',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PathBarComponent,
    MaterialModule,
    ReportOverviewComponent,
    ReportStepperComponent,
    ReportDataValidationComponent,
    ReportSpectralAnalysisComponent,
    ScrollableBorderedContainerComponent,
    ReportSectionComponent,
  ],
  templateUrl: './one-page-signal-report.component.html',
})
export class OnePageSignalReportComponent implements OnInit {
  signalId = this.store.selectSignal(signalsFeature.selectPageSignalId);
  reportTitle: Signal<string> = this.store.selectSignal(signalReportFeature.selectNameWithoutExtension);
  loadingState: Signal<LoadingState> = this.store.selectSignal(signalReportFeature.selectLoadingState);
  sensor = this.store.selectSignal(signalReportFeature.selectSignalSensor);
  equipment = this.store.selectSignal(signalReportFeature.selectSignalEquipment);
  loading = computed(() => this.loadingState() === LoadingState.Loading);
  public container = viewChild.required<string, ElementRef>('container', { read: ElementRef });

  stepper = viewChild.required<ReportStepperComponent>(ReportStepperComponent);
  sections = viewChildren(ReportSectionComponent);

  anchors = this.store.selectSignal(signalReportFeature.selectAnchors);

  overviewSection = OVERVIEW_SECTION;
  spectralAnalysisSection = SPECTRAL_ANALYSIS_SECTION;
  dataValidationSection = DATA_VALIDATION_SECTION;

  steps = signal<Step[]>([]);

  constructor(
    private store: Store,
    private router: ProjectRouter
  ) {
    this.store.dispatch(EquipmentsActions.equipmentsFetchRequestedIfNotInit());

    effect(() => {
      this.steps.set(
        this.sections()
          .map(section =>
            REPORT_STRUCTURE.sections.find(reportSection => reportSection.anchorId === section.sectionData().anchorId)
          )
          .filter(section => section !== undefined)
      );
    });
  }

  ngOnInit(): void {
    const signalId = this.signalId();

    if (signalId) {
      this.store.dispatch(SignalReportActions.signalBaseDataFetchRequested({ signalId }));
    }
  }

  downloadSignal() {
    const signalId = this.signalId();

    if (signalId) {
      this.store.dispatch(SignalsActions.signalDownloadRequested({ signalId }));
    }
  }

  navigateToRoot() {
    this.router.navigateByUrl(`/operation`);
  }

  navigateToEquipment(equipment: Equipment) {
    this.router.navigateByUrl(`/equipment/${equipment.id}`);
  }

  navigateToSensor(sensor: Sensor) {
    this.router.navigateByUrl(`/sensor/${sensor.id}`);
  }

  navigateToAnchor = async (anchorId: string) => {
    const anchors = this.anchors();

    if (!anchors.has(anchorId)) {
      return;
    }

    const anchoredContent = anchors.get(anchorId);

    if (anchoredContent === undefined) {
      return;
    }

    await anchoredContent.sectionExpansionCallback();

    anchoredContent.htmlElement.scrollIntoView({ behavior: 'smooth' });
  };

  onRefreshClicked() {
    const signalId = this.signalId();

    if (signalId) {
      this.store.dispatch(SignalReportActions.signalBaseDataFetchRequested({ signalId }));
    }
  }

  onScroll(): void {
    const sectionAnchor = this.findScrolledSectionAnchor();

    this.stepper().setScrolledStep(sectionAnchor);
  }

  onSectionExpansionToggled(): void {
    this.onScroll();
  }

  private findScrolledSectionAnchor() {
    const container = this.container();
    const container_rect = container.nativeElement.getBoundingClientRect();

    const container_middle = (container_rect.bottom - container_rect.top) / 2;

    const sections = [...this.sections()];
    const anchoredSection =
      sections.reverse().find(section => section.nativeElement.getBoundingClientRect().top < container_middle) ??
      sections[0];

    return anchoredSection.sectionData().anchorId;
  }
}
