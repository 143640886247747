import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-compute-menu',
  templateUrl: './compute-menu.component.html',
  imports: [MaterialModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComputeMenuComponent {
  menuTrigger = input.required<MatMenuTrigger>();
  recomputeDataValidation = output();
  recomputeSpectralAnalysis = output();
}
