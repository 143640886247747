import {
  HarmonicSeriesTrajectory,
  HarmonicSeriesTrajectoryDto,
  HarmonicTrajectory,
  PeakTrajectory,
  PeakTrajectoryDto,
  SensorTrajectories,
  SensorTrajectoriesDto,
  TrajectoryBase,
  TrajectoryDtoBase,
  TrajectoryType,
} from '../interfaces/trajectory.interface';

const customToFixed = (value: number, precision?: number) => {
  const power = Math.pow(10, precision ?? 0);
  return String(Math.round(value * power) / power);
};

const trajectoryName = (t: TrajectoryDtoBase, name: string) =>
  t.labels.length > 0 ? t.labels.map(l => l.name).join(', ') : name;

const mapBaseFromDto = (dto: TrajectoryDtoBase, name: string): TrajectoryBase => ({
  id: dto.id,
  name: trajectoryName(dto, name),
  frequency: dto.frequency,
  detectionPercentage: dto.detectionPercentage,
  frequencyTrendMiniatureContentPath: dto.frequencyTrendMiniatureContentPath,
  energyTrendMiniatureContentPath: dto.energyTrendMiniatureContentPath,
  isLabeled: dto.labels.length != 0,
  type: TrajectoryType.Unknown,
});

const mapPeakFromDto = (dto: PeakTrajectoryDto): PeakTrajectory => ({
  ...mapBaseFromDto(dto, `P ${customToFixed(dto.frequency, 3)}`),
  type: TrajectoryType.PeakTrajectory,
});

const mapHarmonicsFromDto = (dto: HarmonicSeriesTrajectoryDto): HarmonicSeriesTrajectory => {
  const name = `H ${customToFixed(dto.frequency, 3)}`;

  return {
    ...mapBaseFromDto(dto, name),
    type: TrajectoryType.HarmonicSeriesTrajectory,
    harmonicsCountTrendMiniatureContentPath: dto.harmonicsCountTrendMiniatureContentPath,
    averageHarmonicEnergyTrendMiniatureContentPath: dto.averageHarmonicEnergyTrendMiniatureContentPath,
    regularityTrendMiniatureContentPath: dto.regularityTrendMiniatureContentPath,
    thdTrendMiniatureContentPath: dto.thdTrendMiniatureContentPath,
    harmonicFrequenciesMiniatureContentPath: dto.compoundHarmonicFrequencyTrendsMiniatureContentPath,
    harmonicEnergiesMiniatureContentPath: dto.compoundHarmonicEnergyTrendsMiniatureContentPath,
    harmonicTrajectories: dto.harmonicTrajectories.map(trajectory => {
      return {
        id: trajectory.id,
        type: TrajectoryType.HarmonicTrajectory,
        rank: trajectory.rank,
        name,
        frequency: 0,
        detectionPercentage: 0,
        isLabeled: false,
        frequencyTrendMiniatureContentPath: '',
        energyTrendMiniatureContentPath: '',
      } as HarmonicTrajectory;
    }),
  };
};

export const mapTrajectoriesFromDto = (dto: SensorTrajectoriesDto): SensorTrajectories => ({
  ...dto,
  peakTrajectories: dto.peakTrajectories?.map(mapPeakFromDto) ?? null,
  harmonicSeriesTrajectories: dto.harmonicSeriesTrajectories?.map(mapHarmonicsFromDto) ?? null,
});
