import { Component, computed, effect, input, output, signal, viewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { GlGraphComponent, ImageExportOptions } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { download, downloadImageBlob } from '@tools/utilities/download';
import { GraphWrapperBaseLegendComponent } from './graph-wrapper-base-legend/graph-wrapper-base-legend.component';
import { CurveLegend } from './graph-wrapper-base.interface';
import { GraphWrapperDrawerComponent } from './graph-wrapper-drawer/graph-wrapper-drawer.component';
import { GraphExportService } from '@services/graph-export.service';
import { CursorsListComponent } from './cursors-list/cursors-list.component';

const enum DrawerState {
  Close,
  Settings,
  Info,
  Cursors,
}

@Component({
  selector: 'app-graph-wrapper-base',
  imports: [MaterialModule, GraphWrapperBaseLegendComponent, GraphWrapperDrawerComponent, CursorsListComponent],
  templateUrl: './graph-wrapper-base.component.html',
  styleUrl: './graph-wrapper-base.component.scss',
})
export class GraphWrapperBaseComponent {
  xTitle = input.required<string>();
  graphHeight = input<string | undefined>(undefined);
  hasData = input.required<boolean>();
  graph = input.required<GlGraphComponent | undefined>();
  name = input.required<string>();

  showDownload = input(true);
  showExportImage = input(true);
  collapsable = input(true);

  showLegend = input(false);
  showDelete = input(false);
  showSettings = input(false);
  showInfos = input(false);
  showCursors = input(false);
  downloadDisabled = input(false);

  legend = input<CurveLegend[] | undefined>(undefined);

  // Export options that override default ones but can be overriden by user
  imageExportOptions = input<Partial<ImageExportOptions> | undefined>(undefined);

  delete = output();

  panel = viewChild.required<MatExpansionPanel>('panel');

  private drawerState = signal<DrawerState>(DrawerState.Close);
  menuOpen = computed(() => this.drawerState() !== DrawerState.Close);
  drawerShowSettings = computed(() => this.drawerState() === DrawerState.Settings);
  drawerShowInfos = computed(() => this.drawerState() === DrawerState.Info);
  drawerShowCursors = computed(() => this.drawerState() === DrawerState.Cursors);

  cursorsList = viewChild<unknown, CursorsListComponent>('cursorsList', { read: CursorsListComponent });
  userCursors = computed(() => this.cursorsList()?.cursors() ?? []);

  constructor(private exportService: GraphExportService) {
    effect(() => {
      const hasData = this.hasData();
      const showCursors = this.showCursors();
      const showInfos = this.showInfos();
      const showSettings = this.showSettings();
      const drawerState = this.drawerState();
      if (
        !hasData ||
        (drawerState === DrawerState.Cursors && !showCursors) ||
        (drawerState === DrawerState.Info && !showInfos) ||
        (drawerState === DrawerState.Settings && !showSettings)
      ) {
        this.drawerState.set(DrawerState.Close);
      }
    });
  }

  clearCursors = () => {
    this.cursorsList()?.clearCursors();
  };

  onCursors = (event: Event) => {
    event.stopPropagation();
    if (this.drawerShowCursors()) {
      this.drawerState.set(DrawerState.Close);
    } else {
      this.drawerState.set(DrawerState.Cursors);
    }
  };

  onSettings = (event: Event) => {
    event.stopPropagation();
    if (this.drawerShowSettings()) {
      this.drawerState.set(DrawerState.Close);
    } else {
      this.drawerState.set(DrawerState.Settings);
    }
  };

  onInfos = (event: Event) => {
    event.stopPropagation();
    if (this.drawerShowInfos()) {
      this.drawerState.set(DrawerState.Close);
    } else {
      this.drawerState.set(DrawerState.Info);
    }
  };

  onDelete = (event: Event) => {
    this.delete.emit();
    event.stopPropagation();
  };

  onTogglePanel = (event: Event) => {
    this.expanded.update(e => !e);
    event.stopPropagation();
  };

  onExportImage = async (event: Event) => {
    event.stopPropagation();
    // Close menu in case of invisible cursors
    this.drawerState.set(DrawerState.Close);
    const graph = this.graph();
    if (!graph) {
      return;
    }
    const image = await this.exportService.openImageExportDialog(graph, this.imageExportOptions());
    if (image) {
      downloadImageBlob('image.png', image);
    }
  };

  onDownload = async (event: Event) => {
    event.stopPropagation();
    const graph = this.graph();
    if (!graph) {
      return;
    }
    const options = await this.exportService.askForCsvExportOptions(this.xTitle());
    if (options) {
      const csvText = await graph.exportCsv(options);
      download('export.csv', csvText);
    }
  };

  expanded = signal(true);
}
