import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { ResultUnavailableComponent } from '@components/result-unavailable/result-unavailable.component';
import { CyclesComponent } from '@features/spectral-analysis/components/cycles/cycles.component';
import { PeaksAndHarmonicSeriesComponent } from '@features/spectral-analysis/components/peaks-and-harmonic-series/peaks-and-harmonic-series.component';
import { spectralAnalysisFeature } from '@features/spectral-analysis/shared/store/spectral-analysis.feature';
import {
  computationCompleted,
  ComputationStep,
  ComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { Store } from '@ngrx/store';
import { ReportSubSectionComponent } from '../report-sub-section/report-sub-section.component';
import { CyclesSelectionComponent } from '../../../spectral-analysis/components/cycles/cycles-selection/cycles-selection.component';
import { PeaksHarmonicsSelectionComponent } from '@features/spectral-analysis/components/peaks-and-harmonic-series/peaks-harmonics-selection/peaks-harmonics-selection.component';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import {
  ModeName,
  peakModeName,
} from '@features/spectral-analysis/components/peaks-and-harmonic-series/shared/interfaces';
import { SPECTRAL_ANALYSIS_SECTION } from '@features/signal-report/shared/interface/report-structure';

@Component({
  selector: 'app-report-spectral-analysis',
  imports: [
    CyclesComponent,
    PeaksAndHarmonicSeriesComponent,
    ResultUnavailableComponent,
    ReportSubSectionComponent,
    CyclesSelectionComponent,
    PeaksHarmonicsSelectionComponent,
  ],
  templateUrl: './report-spectral-analysis.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSpectralAnalysisComponent {
  public filename = this.store.selectSignal(signalReportFeature.selectNameWithoutExtension);

  public cycles = this.store.selectSignal(spectralAnalysisFeature.selectCycles);
  public fusion = this.store.selectSignal(spectralAnalysisFeature.selectFusion);
  public harmonicSeries = this.store.selectSignal(spectralAnalysisFeature.selectHarmonicSeries);

  public selectedMode = signal<ModeName>(peakModeName);

  public hasHarmonics = computed(() => this.harmonicSeries().progress !== ComputationStepProgress.NotComputed);

  public peaksAndHarmonicsSubsectionTitle = computed(() => {
    let title = 'Resulting peaks';

    if (this.hasHarmonics()) {
      title += ' and harmonic families';
    }

    return title;
  });

  public ready = (step: ComputationStep): boolean => computationCompleted(step);

  public spectrumsSubSection = SPECTRAL_ANALYSIS_SECTION.subsections['spectrums'];
  public peaksAndHarmonicsSubSection = computed(() => {
    return {
      ...SPECTRAL_ANALYSIS_SECTION.subsections['spectralAnalysisResults'],
      name: this.peaksAndHarmonicsSubsectionTitle(),
    };
  });

  constructor(private store: Store) {}
}
