import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { LoadingState } from '@shared/interfaces/loading-state';

import { Cycles, defaultCyclesWithLoadingState } from '../interface/cycles';
import { defaultFusionWithLoadingState, Fusion } from '../interface/fusion';
import { defaultHarmonicSeriesWithLoadingState, HarmonicSeries } from '../interface/harmonic-series';

export const featureName = 'spectral-analysis';

export interface SpectralAnalysisState {
  signalId: AStrionSignalId | null;
  cycles: Cycles;
  fusion: Fusion;
  harmonicSeries: HarmonicSeries;
  progress: ComputationStepProgress;
  loadingState: LoadingState;
}

export const SPECTRAL_ANALYSIS_INITIAL_STATE: SpectralAnalysisState = {
  signalId: null,
  cycles: defaultCyclesWithLoadingState(LoadingState.Unknown),
  fusion: defaultFusionWithLoadingState(LoadingState.Unknown),
  harmonicSeries: defaultHarmonicSeriesWithLoadingState(LoadingState.Unknown),
  progress: ComputationStepProgress.Unknown,
  loadingState: LoadingState.Unknown,
};
