import { Component, computed, input } from '@angular/core';
import { PMC } from '@features/spectral-analysis/shared/interface/pfa-pmc-thresholds';
import { peakPmcToString, peakTypeToString } from '@features/spectral-analysis/shared/utils/peak-utils';
import { toDb, toPercent } from '@tools/utilities/units';

import { FixedSizeItemVirtualScrollTableComponent } from '../fixed-size-item-virtual-scroll-table/fixed-size-item-virtual-scroll-table.component';
import { ColDef, TableOptions } from '../fixed-size-item-virtual-scroll-table/interfaces/interfaces';

export interface TablePeak {
  index: number;
  frequency: number;
  amplitude: number;
  rms: number;
  psd: number;
  type: string;
  pmc: PMC;
  localSNR: number;
  relativeBandWidth3dBRatio: number;
  totalQuadraticError: number;
  quadraticError3dB: number;
}

export type TablePeakColumnDefinition = ColDef<TablePeak>;

const baseColumns: TablePeakColumnDefinition[] = [
  {
    name: 'Peak',
    propertyName: 'index',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => peak.index.toString(),
  },
  {
    name: 'Frequency (Hz)',
    propertyName: 'frequency',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => peak.frequency.toFixed(2),
  },
  {
    name: 'Time Amplitude',
    propertyName: 'amplitude',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => peak.amplitude.toFixed(4),
  },
  {
    name: 'RMS Amplitude (u)',
    propertyName: 'rms',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => peak.rms.toFixed(3),
  },
  {
    name: 'PSD Amplitude (u²/Hz)',
    propertyName: 'psd',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => peak.psd.toFixed(3),
  },
  {
    name: 'Class',
    propertyName: 'type',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => peakTypeToString(peak.type),
  },
  {
    name: 'Probability of misclassifying (PMC)',
    propertyName: 'pmc',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => peakPmcToString(peak.pmc),
  },
  {
    name: 'Local SNR (dB)',
    propertyName: 'localSNR',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => toDb(peak.localSNR).toFixed(3),
  },
  {
    name: 'Bandwidth % spectral window',
    propertyName: 'relativeBandWidth3dBRatio',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => toPercent(peak.relativeBandWidth3dBRatio).toFixed(0),
  },
  {
    name: 'Total quad. error % spectral window',
    propertyName: 'totalQuadraticError',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => toPercent(peak.totalQuadraticError).toExponential(4),
  },
  {
    name: '-3dB quad. error % spectral window',
    propertyName: 'quadraticError3dB',
    sortable: true,
    formattedPropertyValue: (peak: TablePeak): string => toPercent(peak.quadraticError3dB).toExponential(4),
  },
];

@Component({
  selector: 'app-peaks-table',
  imports: [FixedSizeItemVirtualScrollTableComponent],
  templateUrl: './peaks-table.component.html',
})
export class PeaksTableComponent {
  public peaks = input.required<TablePeak[]>();
  public additionalColumns = input<TablePeakColumnDefinition[]>([]);

  public tableOptions = computed(
    (): TableOptions<number, TablePeak> => ({
      itemIdRetrieveFn: (data: TablePeak) => data.index,
      columnDefinitions: [...baseColumns, ...this.additionalColumns()],
      rowHeight: 60,
      emptyDataMessage: 'No peak matches the current criteria.',
      minBufferedItemsCount: 30,
      maxAdditionnalBufferedItemsCount: 5,
    })
  );

  public filterPredicate = input<(peak: TablePeak) => boolean>(() => true);
}
