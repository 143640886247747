import { Component, input, output } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import { ColorPickerModule } from 'ngx-color-picker';

const presetColors = [
  '#880015',
  '#ed1c24',
  '#ff7f27',
  '#ff7f27',
  '#22b14c',
  '#00a2e8',
  '#3f48cc',
  '#a349a4',
  '#b97a57',
  '#ffaec9',
  '#ffc90e',
  '#efe4b0',
  '#b5e61d',
  '#99d9ea',
  '#7092be',
  '#7092be',
  '#000000',
  '#747474',
];

@Component({
  selector: 'app-color-select',
  imports: [MaterialModule, ColorPickerModule],
  templateUrl: './color-select.component.html',
})
export class ColorSelectComponent {
  color = input.required<string>();

  colorChanged = output<string>();

  readonly presetColors = presetColors;

  constructor() {}

  onClick = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  onChange = (color: string) => {
    this.colorChanged.emit(color);
  };
}
