@let _cursor = userCursor();

<mat-expansion-panel (opened)="open()" (closed)="close()">
  <mat-expansion-panel-header>
    <ng-content select="[cursor-description]" />
    <span class="flex-grow"></span>
    <app-color-select
      class="self-center"
      [color]="_cursor.mainColor ?? '#ff0000'"
      (colorChanged)="updateMainColor($event)" />
    <app-color-select class="self-center" [color]="_cursor.color ?? '#0000ff'" (colorChanged)="updateColor($event)" />

    <button mat-icon-button (click)="deleted.emit(); $event.stopPropagation()"><mat-icon>delete</mat-icon></button>
  </mat-expansion-panel-header>
  <div class="flex flex-col">
    <ng-content select="[cursor-form]" />
    <div class="flex flex-row gap-3">
      <mat-form-field>
        <mat-label>Width</mat-label>
        <input type="number" matInput [ngModel]="_cursor.width" (ngModelChange)="updateWidth($event)" />
      </mat-form-field>
    </div>
  </div>
</mat-expansion-panel>
