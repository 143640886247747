@let _checked = checked();
<div class="relative h-[66px] w-[130px] rounded border" [ngClass]="containerClass()">
  @if (_checked) {
    <div class="bg-neutral-90 absolute z-0 h-[64px] w-[128px] rounded"></div>
  }
  <img
    class="absolute z-10 h-[64px] w-[128px]"
    #miniature
    src="assets/miniature_unloaded.png"
    matTooltip="Preview not available, you can still visualize trend"
    matTooltipPosition="right"
    matTooltipShowDelay="800" />
  @if (_checked) {
    <mat-icon class="fg-primary absolute z-20 h-[64px] w-[128px]">check</mat-icon>
  }
</div>
