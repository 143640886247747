<h1 mat-dialog-title>Graph export</h1>

<mat-dialog-content>
  <div class="flex h-full w-full flex-col gap-2 overflow-y-auto overflow-x-clip p-0">
    <div class="h-[60%] w-full">
      @if (previewImage$ | async; as image) {
        <app-graph-image-export-dialog-preview [image]="image" class="h-full w-full" />
      }
    </div>

    <div class="grid w-full flex-initial grid-cols-[auto_min-content] items-center gap-2">
      <!-- Title -->
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Title</mat-label>
        <input matInput [(ngModel)]="title" data-testid="export-title-input" />
      </mat-form-field>
      @if (showButtons) {
        <app-graph-image-export-dialog-buttons
          data-testid="export-title-buttons"
          (onAction)="actionOnTitle($event)"
          [isGlobalDefault]="titleIsGlobalDefault()"
          [isGraphDefault]="titleIsGraphDefault()"
          defaultChoiceDisabled />
      } @else {
        <span></span>
      }

      <!--Font sizes-->
      <div class="flex gap-2">
        <mat-form-field subscriptSizing="dynamic" class="max-w-[150px]">
          <mat-label>Title size (px)</mat-label>
          <input matInput [(ngModel)]="titleSize" type="number" data-testid="export-title-size-input" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" class="max-w-[150px]">
          <mat-label>Axis title size (px)</mat-label>
          <input matInput [(ngModel)]="axisSize" type="number" data-testid="export-axis-size-input" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" class="max-w-[150px]">
          <mat-label>Axis values size (px)</mat-label>
          <input matInput [(ngModel)]="ticksSize" type="number" data-testid="export-ticks-size-input" />
        </mat-form-field>
      </div>
      @if (showButtons) {
        <app-graph-image-export-dialog-buttons
          data-testid="export-fonts-buttons"
          (onAction)="actionOnFonts($event)"
          [isGlobalDefault]="fontsAreGlobalDefault()"
          [isGraphDefault]="fontsAreGraphDefault()" />
      } @else {
        <span></span>
      }

      <!--Image size-->
      <div class="flex gap-2">
        <mat-form-field subscriptSizing="dynamic" class="flex-grow">
          <mat-label>Width</mat-label>
          <input matInput [(ngModel)]="width" type="number" data-testid="export-width-input" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" class="flex-grow">
          <mat-label>Height</mat-label>
          <input matInput [(ngModel)]="height" type="number" data-testid="export-height-input" />
        </mat-form-field>
      </div>
      @if (showButtons) {
        <app-graph-image-export-dialog-buttons
          data-testid="export-size-buttons"
          [isGlobalDefault]="sizeIsGlobalDefault()"
          [isGraphDefault]="sizeIsGraphDefault()"
          (onAction)="actionOnSize($event)" />
      } @else {
        <span></span>
      }

      <div class="flex gap-2">
        <mat-checkbox class="example-margin" [(ngModel)]="displayCurveLegends">Display curve legends</mat-checkbox>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  @if (hasStorage) {
    <button mat-button class="secondary-button" (click)="resetSettings()">
      <mat-icon>reset_settings</mat-icon>Reset all settings
    </button>
  }
  <button mat-button class="error-button" (click)="cancel()"><mat-icon>close</mat-icon>Cancel</button>
  <button mat-raised-button class="primary-button" (click)="export()" #exportButton>
    <mat-icon>photo_camera</mat-icon>Export
  </button>
</mat-dialog-actions>
