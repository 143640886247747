export class StatefulMap<K, V> {
  // To be mutable even if instance is readonly
  private _state: { map?: Map<K, V> };

  constructor(input?: Map<K, V>, copy = false) {
    if (input) {
      this._state = { map: copy ? new Map(input) : input };
    } else {
      this._state = { map: new Map() };
    }
  }

  static fromEntries<K, V>(input: Array<[K, V]>) {
    return new StatefulMap<K, V>(new Map(input));
  }

  get(key: K): Readonly<V> | undefined {
    const map = this._stateMap;
    return map.get(key);
  }

  contains(key: K): boolean {
    return this._stateMap.has(key);
  }

  set(key: K, value: V): StatefulMap<K, V> {
    const map = this._stateMap;
    map.set(key, value);
    return this._move();
  }

  // TODO it always assumes a change, find a way not to ?
  update(key: K, updateFn: (value: V) => V): StatefulMap<K, V> {
    if (!this.contains(key)) {
      throw Error('Key does not exist');
    }

    const map = this._stateMap;
    const value = map.get(key)!; // As V can be undefined as a type, we check for its presence above
    map.set(key, updateFn(value!));
    return this._move();
  }

  delete(key: K): StatefulMap<K, V> {
    const map = this._stateMap;
    if (map.delete(key)) {
      return this._move();
    } else {
      // no need to move
      return this;
    }
  }

  filterKeys(filterFn: (key: K) => boolean): StatefulMap<K, V> {
    return StatefulMap.fromEntries(this.entries.filter(([k]) => filterFn(k)));
  }

  filterValues(filterFn: (value: V) => boolean): StatefulMap<K, V> {
    return StatefulMap.fromEntries(this.entries.filter(([, v]) => filterFn(v)));
  }

  filterValuesType<U = V>(filterFn: (value: V | U) => value is U): StatefulMap<K, U> {
    return StatefulMap.fromEntries(this.entries.filter(([, v]) => filterFn(v)) as unknown as [K, U][]);
  }

  mapValues<U = V>(mapFn: (value: V) => U) {
    const newMap = new Map<K, U>();
    for (const [k, v] of this._stateMap.entries()) {
      newMap.set(k, mapFn(v));
    }
    return new StatefulMap(newMap);
  }

  reduce<T>(reduceFn: (prev: T, value: V, key: K) => T, initialValue: T) {
    let sum = initialValue;
    for (const [k, v] of this._stateMap.entries()) {
      sum = reduceFn(sum, v, k);
    }
    return sum;
  }

  reduceValues<T>(reduceFn: (prev: T, value: V) => T, initialValue: T): T {
    let sum = initialValue;
    for (const v of this._stateMap.values()) {
      sum = reduceFn(sum, v);
    }
    return sum;
  }

  anyValue(anyFn: (v: V) => boolean): boolean {
    for (const v of this._stateMap.values()) {
      if (anyFn(v)) {
        return true;
      }
    }
    return false;
  }

  get size() {
    return this._stateMap.size;
  }

  get entries(): Array<[K, V]> {
    const entries = [];
    for (const entry of this._stateMap) {
      entries.push(entry);
    }
    return entries;
  }

  get values(): Array<V> {
    const values = [];
    for (const value of this._stateMap.values()) {
      values.push(value);
    }
    return values;
  }

  private get _stateMap() {
    const map = this._state.map;
    if (!map) {
      throw Error('StatefulMap is moved.');
    }
    return map;
  }

  private _move(): StatefulMap<K, V> {
    const newMap = new StatefulMap(this._state.map!);
    this._state.map = undefined;
    return newMap;
  }
}
