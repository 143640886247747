@let hasHarmonics = nHarmonics() > 0;
@let hasSidebands = nSidebands() > 0;
@let hasIntervals = nIntervals() > 0;

<mat-accordion #accordion>
  <div class="flex flex-row items-center gap-4">
    <img [src]="harmonicsIcon" width="29" height="29" />
    <span class="mt-2 font-bold">Harmonics cursors</span>
    <button mat-icon-button (click)="onHarmonicsAdd()"><mat-icon>add</mat-icon></button>
  </div>
  @if (hasHarmonics) {
    @for (cursor of harmonicsEntries(); track cursor[0]) {
      @let cursorIdx = cursor[0];
      <app-harmonics-cursor-item
        [cursor]="cursor[1]"
        class="w-full"
        (deleted)="deleteCursor(cursorIdx)"
        (updated)="update(cursorIdx, $event)"
        (opened)="cursorOpened(cursorIdx)"
        (closed)="cursorClosed(cursorIdx)" />
    }
  }
  <div class="flex flex-row items-center gap-4">
    <img [src]="sidebandsIcon" width="29" height="29" />
    <span class="mt-2 font-bold">Sidebands cursors</span>
    <button mat-icon-button (click)="onSidebandsAdd()"><mat-icon>add</mat-icon></button>
  </div>
  @if (hasSidebands) {
    @for (cursor of sidebandsEntries(); track cursor[0]) {
      @let cursorIdx = cursor[0];
      <app-sidebands-cursor-item
        [cursor]="cursor[1]"
        class="w-full"
        (deleted)="deleteCursor(cursorIdx)"
        (updated)="update(cursorIdx, $event)"
        (opened)="cursorOpened(cursorIdx)"
        (closed)="cursorClosed(cursorIdx)" />
    }
  }
  <div class="flex flex-row items-center gap-4">
    <img [src]="intervalIcon" width="29" height="29" />
    <span class="mt-2 font-bold">Interval cursors</span>
    <button mat-icon-button (click)="onIntervalAdd()"><mat-icon>add</mat-icon></button>
  </div>
  @if (hasIntervals) {
    @for (cursor of intervalsEntries(); track cursor[0]) {
      @let cursorIdx = cursor[0];
      <app-interval-cursor-item
        [cursor]="cursor[1]"
        class="w-full"
        (updated)="update(cursorIdx, $event)"
        (deleted)="deleteCursor(cursorIdx)"
        (opened)="cursorOpened(cursorIdx)"
        (closed)="cursorClosed(cursorIdx)" />
    }
  }
</mat-accordion>
