import { Component, effect, input, linkedSignal, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IntervalMarkerType } from '@astrion-webtools/graph';
import { ColorSelectComponent } from '@components/color-select/color-select.component';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-cursor-item-base',
  imports: [MaterialModule, FormsModule, ColorSelectComponent],
  templateUrl: './cursor-item-base.component.html',
})
export class CursorItemBaseComponent<T extends IntervalMarkerType> {
  cursor = input.required<T>();

  // No infinite change detection as it won't trigger when reinputing update
  userCursor = linkedSignal(() => this.cursor());

  public updateCursor(updateFn: (cursor: T) => T) {
    this.userCursor.update(cursor => updateFn(cursor as T));
  }

  deleted = output<void>();
  updated = output<T>();
  opened = output<void>();
  closed = output<void>();

  open = () => {
    this.opened.emit();
  };

  close = () => {
    this.closed.emit();
  };

  updateMainColor = (mainColor: string) => {
    this.userCursor.update(cursor => ({ ...cursor, mainColor }));
  };

  updateColor = (color: string) => {
    this.userCursor.update(cursor => ({ ...cursor, color }));
  };

  updateWidth = (width: number) => {
    this.userCursor.update(cursor => ({ ...cursor, width }));
  };

  constructor() {
    effect(() => {
      const prev = this.cursor();
      const updated = this.userCursor();
      // Avoid unnecessary trigger on reinputing update
      if (prev !== updated) {
        this.updated.emit(updated);
      }
    });
  }
}
