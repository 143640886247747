import { HarmonicSerie } from '@features/spectral-analysis/shared/interface/harmonic-series';

export const peakModeName = 'peaks';
export const harmonicsModeName = 'harmonics';

export type ModeName = 'peaks' | 'harmonics';

export interface ColoredHarmonicSerie extends HarmonicSerie {
  color: string;
}
