import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Progress } from '@app-types/progress.interface';
import environment from '@environments/environment.json';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { SignalContentDto } from '@features/signal-overview/shared/interface/signal-content';
import {
  AStrionSignalDto,
  AStrionSignalId,
  AStrionSignalPayload,
  UploadResponseAStrionSignalDto,
} from '@features/signals/shared/interface/astrion-signal.interface';
import { AStrionWebError } from '@modules/error-handling/app-error/app-error.class';
import { uploadProgress } from '@tools/operators/upload-progress.operator';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalsApiService {
  private base_url: string;
  constructor(private httpClient: HttpClient) {
    this.base_url = `${environment.BASE_API_URL}/operation-management/signals`;
  }

  getSensorSignals(sensorId: SensorId): Observable<AStrionSignalDto[]> {
    return this.httpClient.get<AStrionSignalDto[]>(`${this.base_url}/list-signals/${sensorId}`);
  }

  getSignal(signalId: AStrionSignalId): Observable<AStrionSignalDto> {
    return this.httpClient.get<AStrionSignalDto>(`${this.base_url}/get-signal/${signalId}`);
  }

  downloadSignal(signalId: AStrionSignalId): Observable<[name: string, content: Blob]> {
    return this.httpClient
      .get(`${this.base_url}/download/${signalId}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((res: HttpResponse<Blob>) => {
          const content = res.body;

          if (content === null) {
            throw new AStrionWebError('No content in file.', 'Something went wrong. Could not download signal.', true);
          }

          const FILENAME_RE = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

          const contentDisposition = res.headers.get('content-disposition') || '';
          const matches = FILENAME_RE.exec(contentDisposition);

          if (matches === null || !matches[1]) {
            throw new AStrionWebError(
              'Missing filename for file.',
              'Something went wrong. Could not download signal.',
              true
            );
          }

          const filename = matches![1].replace(/['"]/g, '');

          return [filename, content!];
        })
      );
  }

  deleteSignal(signalId: AStrionSignalId): Observable<AStrionSignalId> {
    return this.httpClient.delete<AStrionSignalDto>(`${this.base_url}/${signalId}`).pipe(map(signal => signal.id));
  }

  updateSignal(signalId: AStrionSignalId, signal: AStrionSignalPayload): Observable<void> {
    return this.httpClient.put<void>(`${this.base_url}/edit-signal/${signalId}/`, signal);
  }

  uploadSignals(sensorId: SensorId, files: File[]): Observable<Progress | UploadResponseAStrionSignalDto[]> {
    const formData = new FormData();
    files.forEach(file => formData.append('files[]', file));
    formData.append('sensorId', sensorId as string);

    const request = new HttpRequest('POST', `${this.base_url}/upload`, formData, {
      reportProgress: true,
    });
    return this.httpClient.request(request).pipe(uploadProgress<UploadResponseAStrionSignalDto[]>());
  }

  getSignalContent(signalId: AStrionSignalId): Observable<SignalContentDto> {
    return this.httpClient.get<SignalContentDto>(`${this.base_url}/get-content/${signalId}`);
  }
}
