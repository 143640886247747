import { Component, computed, input, output, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DisplayNumberPipe, IntervalMarker } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { CursorItemBaseComponent } from '../cursor-item-base/cursor-item-base.component';

@Component({
  selector: 'app-interval-cursor-item',
  imports: [MaterialModule, FormsModule, CursorItemBaseComponent, DisplayNumberPipe],
  templateUrl: './interval-cursor-item.component.html',
})
export class IntervalCursorItemComponent {
  cursor = input.required<IntervalMarker>();

  updated = output<IntervalMarker>();
  deleted = output<void>();
  opened = output<void>();
  closed = output<void>();

  itemBase = viewChild.required<unknown, CursorItemBaseComponent<IntervalMarker>>('itemBase', {
    read: CursorItemBaseComponent<IntervalMarker>,
  });

  length = computed(() => {
    const cursor = this.cursor();
    return (cursor.end ?? cursor.start) - cursor.start;
  });

  updateStart(start: number) {
    this.itemBase().updateCursor(cursor => ({ ...cursor, start }));
  }

  updateEnd(end: number) {
    this.itemBase().updateCursor(cursor => ({ ...cursor, end }));
  }

  intervalChange = (length: number) => {
    this.itemBase().updateCursor(cursor => ({ ...cursor, end: cursor.start + length }));
  };
}
