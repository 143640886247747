@let _loading = isLoading();
@let _canEdit = canEdit();

<div class="mt-4 flex w-full flex-grow flex-col">
  <div class="relative flex w-full flex-col">
    @if (_loading) {
      <mat-progress-bar class="absolute -top-1 left-0 z-50 w-full" mode="indeterminate" />
    }

    <div class="mb-1 flex w-full flex-row items-center">
      <span class="m3-title-large flex-none select-none">Sensors</span>

      <button mat-icon-button matTooltip="Refresh sensors" (click)="refreshClicked.emit()" [disabled]="_loading">
        <mat-icon>refresh</mat-icon>
      </button>

      @if (_canEdit) {
        <button mat-icon-button matTooltip="New Sensor" (click)="openCreateSensorDialog()" [disabled]="_loading">
          <mat-icon>add</mat-icon>
        </button>
      }
    </div>
  </div>
  <div class="relative flex w-full flex-grow select-none flex-col">
    <app-scrollable-bordered-container class="relative flex-grow" [fadeTop]="true" [fadeBottom]="true">
      <mat-table
        [dataSource]="datasource()"
        matSortActive="name"
        matSortDirection="asc"
        class="m3-body-medium m-0 p-0"
        matSort>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="border-outline-variant w-[15%] flex-initial" mat-sort-header>
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let sensor" class="border-outline-variant w-[15%] flex-initial">
            <span>
              {{ sensor.name }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sensorType">
          <mat-header-cell class="border-outline-variant" *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
          <mat-cell class="border-outline-variant" *matCellDef="let sensor">
            <span>
              {{ sensor.sensorType }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="model">
          <mat-header-cell class="border-outline-variant" *matHeaderCellDef mat-sort-header>Model</mat-header-cell>
          <mat-cell class="border-outline-variant" *matCellDef="let sensor">
            <span>
              {{ sensor.model }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="component">
          <mat-header-cell class="border-outline-variant" *matHeaderCellDef mat-sort-header>Component</mat-header-cell>
          <mat-cell class="border-outline-variant" *matCellDef="let sensor">
            <span>
              {{ sensor.component }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="collectPoint">
          <mat-header-cell class="border-outline-variant" *matHeaderCellDef mat-sort-header>
            Collect Point</mat-header-cell
          >
          <mat-cell class="border-outline-variant" *matCellDef="let sensor">
            <span>
              {{ sensor.collectPoint }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="orientation">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="border-outline-variant">
            Orientation
          </mat-header-cell>
          <mat-cell class="border-outline-variant" *matCellDef="let sensor">
            <span>
              {{ sensor.orientation }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-containter matColumnDef="signalsCount">
          <mat-header-cell *matHeaderCellDef class="border-outline-variant w-[7%] flex-initial" mat-sort-header>
            Signals
          </mat-header-cell>
          <mat-cell *matCellDef="let sensor" class="border-outline-variant w-[7%] flex-initial">
            {{ numberOfSignals(sensor) }}
          </mat-cell>
        </ng-containter>

        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef class="border-outline-variant w-[75px] flex-initial" />
          <mat-cell *matCellDef="let item" class="border-outline-variant w-[75px] flex-initial">
            <button
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [matMenuTriggerRestoreFocus]="false"
              mat-icon-button
              (click)="$event.stopPropagation()"
              (menuOpened)="menuOpened()"
              (menuClosed)="menuClosed()">
              <mat-icon>more_horiz</mat-icon>
              <mat-menu #menu="matMenu">
                <app-context-menu
                  [menuTrigger]="menuTrigger"
                  [allowDownload]="false"
                  [allowOpenInNew]="true"
                  [allowDelete]="_canEdit"
                  [allowEdit]="_canEdit"
                  (edit)="edit(item)"
                  (openInNew)="openInNew(item)"
                  (delete)="delete(item)" />
              </mat-menu>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns; sticky: true" class="bg-scheme-surface" />
        <mat-row
          *matRowDef="let sensor; columns: columns"
          class="hover:bg-tertiary-98 cursor-pointer bg-white"
          matRipple
          [matRippleDisabled]="true"
          (click)="onClick(sensor)"
          (contextmenu)="onRightClick($event, sensor)" />

        <div *matNoDataRow class="flex flex-row justify-center">
          @if (!_loading) {
            <span>
              No sensors.
              @if (_canEdit) {
                You can add sensors using « + » button
              }
            </span>
          }
        </div>
      </mat-table>
    </app-scrollable-bordered-container>
  </div>
</div>

<div
  class="fixed opacity-0"
  #rightTrigger="matMenuTrigger"
  [matMenuTriggerFor]="rightClickMenu"
  [style.left]="rightClickMenuPosition.x"
  [style.top]="rightClickMenuPosition.y"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"></div>

<mat-menu #rightClickMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <app-context-menu
      [menuTrigger]="rightTrigger"
      [allowDownload]="false"
      [allowOpenInNew]="true"
      [allowDelete]="canEdit()"
      (openInNew)="openInNew(item)"
      (delete)="delete(item)" />
  </ng-template>
</mat-menu>
