import { Component, input, output, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DisplayNumberPipe, HarmonicsMarker } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { CursorItemBaseComponent } from '../cursor-item-base/cursor-item-base.component';

@Component({
  selector: 'app-sidebands-cursor-item',
  imports: [MaterialModule, FormsModule, CursorItemBaseComponent, DisplayNumberPipe],
  templateUrl: './sidebands-cursor-item.component.html',
})
export class SidebandsCursorItemComponent {
  cursor = input.required<HarmonicsMarker>();

  updated = output<HarmonicsMarker>();
  deleted = output<void>();
  opened = output<void>();
  closed = output<void>();

  itemBase = viewChild.required<unknown, CursorItemBaseComponent<HarmonicsMarker>>('itemBase', {
    read: CursorItemBaseComponent<HarmonicsMarker>,
  });

  updateOffset = (offset: number) => this.itemBase().updateCursor(cursor => ({ ...cursor, offset }));
  updateInterval = (interval: number) => this.itemBase().updateCursor(cursor => ({ ...cursor, interval }));
}
