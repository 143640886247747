import { Component, computed, effect, input, signal, viewChild } from '@angular/core';
import {
  GlGraphComponent,
  HarmonicsMarker,
  ImagesService,
  IntervalMarker,
  IntervalMarkerType,
  isInterval,
  isPositiveHarmonics,
  PositiveHarmonicsMarker,
} from '@astrion-webtools/graph';
import { StatefulMap } from '@tools/utilities/stateful-map';
import { MaterialModule } from '@modules/material.module';
import { HarmonicsCursorItemComponent } from './harmonics-cursor-item/harmonics-cursor-item.component';
import { SidebandsCursorItemComponent } from './sidebands-cursor-item/sidebands-cursor-item.component';
import { IntervalCursorItemComponent } from './interval-cursor-item/interval-cursor-item.component';
import { MatAccordion } from '@angular/material/expansion';

function isSidebands(marker: IntervalMarkerType): marker is HarmonicsMarker {
  return !isPositiveHarmonics(marker) && !isInterval(marker);
}

@Component({
  selector: 'app-cursors-list',
  imports: [MaterialModule, HarmonicsCursorItemComponent, SidebandsCursorItemComponent, IntervalCursorItemComponent],
  templateUrl: './cursors-list.component.html',
})
export class CursorsListComponent {
  graph = input.required<GlGraphComponent | undefined>();
  isOpen = input.required<boolean>();

  accordion = viewChild.required('accordion', { read: MatAccordion });

  counter = 0;
  cursorsMap = signal<StatefulMap<number, IntervalMarkerType>>(new StatefulMap());
  openCursor = signal<number | undefined>(undefined);

  harmonicsEntries = computed<[number, PositiveHarmonicsMarker][]>(
    () => this.cursorsMap().filterValuesType(isPositiveHarmonics).entries
  );
  nHarmonics = computed(() => this.harmonicsEntries().length);

  sidebandsEntries = computed<[number, HarmonicsMarker][]>(
    () => this.cursorsMap().filterValuesType(isSidebands).entries
  );
  nSidebands = computed(() => this.sidebandsEntries().length);

  intervalsEntries = computed<[number, IntervalMarker][]>(() => this.cursorsMap().filterValuesType(isInterval).entries);
  nIntervals = computed(() => this.intervalsEntries().length);

  cursors = computed<IntervalMarkerType[]>(() => {
    const openCursorIdx = this.openCursor();
    const cursorsMap = this.cursorsMap();
    const entries = cursorsMap.entries;
    return openCursorIdx === undefined
      ? entries.map(([, v]) => v)
      : entries.filter(([idx]) => idx === openCursorIdx).map(([, v]) => v);
  });

  clearCursors = () => {
    this.openCursor.set(undefined);
    this.cursorsMap.set(new StatefulMap());
  };

  onHarmonicsAdd = () => {
    this.graph()?.harmonicsMode();
  };

  onSidebandsAdd = () => {
    this.graph()?.sidebandsMode();
  };

  onIntervalAdd = () => {
    this.graph()?.intervalMode();
  };

  cursorOpened = (idx: number) => this.openCursor.set(idx);
  cursorClosed = (idx: number) => this.openCursor.update(prev => (prev === idx ? undefined : prev));

  harmonicsIcon: string | undefined;
  sidebandsIcon: string | undefined;
  intervalIcon: string | undefined;

  constructor(images: ImagesService) {
    this.harmonicsIcon = images.getDataUrl('icon-harmonics');
    this.sidebandsIcon = images.getDataUrl('icon-sidebands');
    this.intervalIcon = images.getDataUrl('icon-interval');

    effect(cleanup => {
      const graph = this.graph();
      const subscriptions = graph
        ? [
            graph.harmonicsSelected.subscribe(this._addCursor),
            graph.sidebandsSelected.subscribe(this._addCursor),
            graph.intervalSelected.subscribe(this._addCursor),
          ]
        : [];
      cleanup(() => {
        subscriptions.forEach(s => s.unsubscribe());
      });
    });
    effect(() => {
      if (!this.isOpen()) {
        this.accordion().closeAll();
      }
    });
  }

  deleteCursor = (idx: number) => {
    this.cursorsMap.update(cursors => cursors.delete(idx));
    this.cursorClosed(idx);
  };

  update = (idx: number, cursor: IntervalMarkerType) => this.cursorsMap.update(cursors => cursors.set(idx, cursor));

  private _addCursor = (cursor: IntervalMarkerType) =>
    this.cursorsMap.update(cursors =>
      cursors.set(this.counter++, { ...cursor, mainColor: '#dd2050', color: '#1020dd', width: 3.0 })
    );
}
