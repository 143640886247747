import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { NoiseFieldData, SpectrumData } from '@features/spectral-analysis/shared/interface/cycles';
import { MaterialModule } from '@modules/material.module';
import { SpectrumParameterComponent } from './spectrum-parameter/spectrum-parameter';

@Component({
  selector: 'app-estimation-arrays',
  imports: [CommonModule, MaterialModule, SpectrumParameterComponent],
  templateUrl: './estimation-arrays.component.html',
})
export class EstimationArraysComponent {
  public cycleSpectrumData = input.required<SpectrumData>();
  public cycleNoiseData = input.required<NoiseFieldData>();
  public cycleIndex = input.required<number>();

  public welchWOSA = computed(() => {
    const spectrumData = this.cycleSpectrumData();

    return spectrumData.method === 'Welch-WOSA';
  });

  public windowTypeLabel = computed(() => (this.welchWOSA() ? '' : 'Autocorrelation') + ' Window type');
  public windowLengthLabel = computed(() => 'Window length' + (this.welchWOSA() ? '' : '(positive delays)'));

  public showSegmentsCount = computed(() => {
    const noiseData = this.cycleNoiseData();

    return noiseData.filterLength != 0;
  });

  public frequencyBin = computed(() => {
    const spectrumData = this.cycleSpectrumData();

    // parseFloat removes unsignificant trailing zeros, but its usage adds a string->float->string conversion cycle
    return parseFloat(spectrumData.frequencyBin.toFixed(5));
  });

  public frequencyResolution = computed(() => {
    const spectrumData = this.cycleSpectrumData();

    // parseFloat removes unsignificant trailing zeros, but its usage adds a string->float->string conversion cycle
    return parseFloat(spectrumData.frequencyResolution.toFixed(5));
  });

  public normalizedVariance = computed(() => {
    const spectrumData = this.cycleSpectrumData();

    // parseFloat removes unsignificant trailing zeros, but its usage adds a string->float->string conversion cycle
    return parseFloat(spectrumData.methodVariance.toFixed(8));
  });
}
