@let _xTitle = xTitle() ?? '';
@let _yTitle = yTitle() ?? '';
@let _oneGraph = oneGraph();

<app-graph-wrapper-base
  #graphWrapper
  [name]="name()"
  [hasData]="hasData()"
  [xTitle]="xTitle() ?? ''"
  [showLegend]="false"
  [showDelete]="showDelete()"
  [showInfos]="showInfos()"
  [showSettings]="showSettings()"
  [showCursors]="_oneGraph"
  [collapsable]="collapsable()"
  [graphHeight]="graphHeight()"
  [downloadDisabled]="!_oneGraph"
  (delete)="delete.emit()"
  [showExportImage]="showExportImage()"
  [imageExportOptions]="imageExportOptions()"
  [graph]="mainGraph()">
  <ng-content select="[headers]" headers />
  <ng-content select="[settings]" settings />
  <ng-content select="[infos]" infos />
  <div class="flex h-full w-full flex-col" graph>
    @for (graph of graphs(); track graph.id) {
      <lib-gl-graph
        #graph
        [enableCursors]="_oneGraph"
        class="w-full flex-grow bg-white"
        [group]="wrapperId"
        [data]="graph.data"
        [id]="graph.id"
        [xTitle]="_xTitle"
        [yTitle]="_yTitle"
        [xAxisDataIsTimestamps]="xAxisDataIsTimestamps()"
        [color]="graph.color"
        [lines]="graph.lines"
        [yScale]="yScale()"
        [yAxisLegendLinear]="yAxisLegendLinear()"
        [yMeanTicks]="yMeanTicks()"
        [xMeanTicks]="xMeanTicks()"
        [displayCursorsButtons]="true"
        [markers]="graphWrapper.userCursors()" />
    }
  </div>
</app-graph-wrapper-base>
