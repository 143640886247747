import { Injectable } from '@angular/core';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DataProxyApiService } from '@services/data-proxy-api.service';
import { map, mergeMap, switchMap } from 'rxjs';

import { SpectralAnalysisApiService } from '../services/spectral-analysis-api.service';
import { mapCyclesFromDto } from '../utils/cycles-mapping';
import { mapFusionFromDto } from '../utils/fusion-mapping';
import { mapHarmonicSeriesFromDto } from '../utils/harmonic-series-mapping';
import { mapSpectralAnalysisFromDto } from '../utils/spectral-analysis-mappings';
import { cycleBytesStorageDescriptor, fusionBytesStorageDescriptor } from '../utils/storage-descriptors';
import { SpectralAnalysisActions } from './spectral-analysis.actions';

@Injectable()
export class SpectralAnalysisEffects {
  constructor(
    private actions$: Actions,
    private api: SpectralAnalysisApiService,
    private dataProxyApi: DataProxyApiService
  ) {}

  tryFetchSignalSpectralAnalysisEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.spectralAnalysisFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getSpectralAnalysis(signalId).pipe(
          map(spectralAnalysisDto =>
            SpectralAnalysisActions.spectralAnalysisFetched({
              signalId,
              spectralAnalysis: mapSpectralAnalysisFromDto(spectralAnalysisDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? SpectralAnalysisActions.spectralAnalysisNotFound({ signalId })
              : SpectralAnalysisActions.spectralAnalysisFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitCyclesFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.spectralAnalysisFetched),
      map(({ signalId, spectralAnalysis }) =>
        SpectralAnalysisActions.cyclesFetched({
          signalId,
          cycles: spectralAnalysis.cycles,
        })
      )
    );
  });

  tryFetchCyclesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.cyclesFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getCyclesData(signalId).pipe(
          map(cyclesDto =>
            SpectralAnalysisActions.cyclesFetched({
              signalId,
              cycles: mapCyclesFromDto(cyclesDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? SpectralAnalysisActions.cyclesNotFound({ signalId })
              : SpectralAnalysisActions.cyclesFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitInitialCycleBytesFetchRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.cyclesFetched),
      map(({ signalId, cycles }) =>
        cycles.data === undefined
          ? SpectralAnalysisActions.cycleBytesFetchRejected()
          : SpectralAnalysisActions.cycleBytesFetchEmissionRequested({ signalId, cycleIndex: 0, cycles })
      )
    );
  });

  emitNextCycleBytesFetchRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.cycleBytesFetchEmissionRequested),
      map(({ signalId, cycleIndex, cycles }) =>
        cycles.data === undefined
          ? SpectralAnalysisActions.cycleBytesFetchRejected()
          : cycleIndex === cycles.data!.length - 1
            ? SpectralAnalysisActions.noAction()
            : SpectralAnalysisActions.cycleBytesFetchEmissionRequested({
                signalId,
                cycleIndex: cycleIndex + 1,
                cycles,
              })
      )
    );
  });

  emitCycleSpectrumBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.cycleBytesFetchEmissionRequested),
      map(({ signalId, cycleIndex, cycles }) =>
        cycles.data === undefined
          ? SpectralAnalysisActions.cycleBytesFetchRejected()
          : cycleIndex >= cycles.data.length
            ? SpectralAnalysisActions.invalidCycleIndexBytesFetchEmissionRequested({ signalId, cycleIndex })
            : SpectralAnalysisActions.cycleSpectrumBytesFetchRequested({
                signalId,
                cycleIndex,
                contentPath: cycles.data![cycleIndex].spectrum.amplitudes.contentPath,
                freqMin: cycles.data![cycleIndex].spectrum.freqMin,
                freqMax: cycles.data![cycleIndex].spectrum.freqMax,
              })
      )
    );
  });

  emitCycleNoiseBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.cycleBytesFetchEmissionRequested),
      map(({ signalId, cycleIndex, cycles }) =>
        cycles.data === undefined
          ? SpectralAnalysisActions.cycleBytesFetchRejected()
          : cycleIndex >= cycles.data.length
            ? SpectralAnalysisActions.invalidCycleIndexBytesFetchEmissionRequested({ signalId, cycleIndex })
            : SpectralAnalysisActions.cycleNoiseBytesFetchRequested({
                signalId,
                cycleIndex,
                contentPath: cycles.data![cycleIndex].noise.amplitudes.contentPath,
                freqMin: cycles.data![cycleIndex].noise.freqMin,
                freqMax: cycles.data![cycleIndex].noise.freqMax,
              })
      )
    );
  });

  tryFetchCycleSpectrumBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.cycleSpectrumBytesFetchRequested),
      mergeMap(({ signalId, cycleIndex, contentPath, freqMin, freqMax }) =>
        this.dataProxyApi
          .getDataBytes(
            contentPath,
            cycleBytesStorageDescriptor(`${signalId}_cycle_${cycleIndex}_spectrum`, freqMin, freqMax)
          )
          .pipe(
            map(dbRowId =>
              SpectralAnalysisActions.cycleSpectrumBytesFetched({ signalId, cycleIndex, dataId: dbRowId })
            ),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? SpectralAnalysisActions.cycleSpectrumBytesNotFound({ signalId, cycleIndex })
                : SpectralAnalysisActions.cycleSpectrumBytesFetchFailed({ signalId, cycleIndex });
            })
          )
      )
    );
  });

  tryFetchCycleNoiseBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.cycleNoiseBytesFetchRequested),
      mergeMap(({ signalId, cycleIndex, contentPath, freqMin, freqMax }) =>
        this.dataProxyApi
          .getDataBytes(
            contentPath,
            cycleBytesStorageDescriptor(`${signalId}_cycle_${cycleIndex}_noise`, freqMin, freqMax)
          )
          .pipe(
            map(dbRowId => SpectralAnalysisActions.cycleNoiseBytesFetched({ signalId, cycleIndex, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? SpectralAnalysisActions.cycleNoiseBytesNotFound({ signalId, cycleIndex })
                : SpectralAnalysisActions.cycleNoiseBytesFetchFailed({ signalId, cycleIndex });
            })
          )
      )
    );
  });

  emitFusionFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.spectralAnalysisFetched),
      map(({ signalId, spectralAnalysis }) =>
        SpectralAnalysisActions.fusionFetched({
          signalId,
          fusion: spectralAnalysis.fusion,
        })
      )
    );
  });

  tryFetchFusionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.fusionFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getFusionData(signalId).pipe(
          map(fusionDto =>
            SpectralAnalysisActions.fusionFetched({
              signalId,
              fusion: mapFusionFromDto(fusionDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? SpectralAnalysisActions.fusionNotFound({ signalId })
              : SpectralAnalysisActions.fusionFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitFusionNoiseMinBytesFetchRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.fusionFetched),
      map(({ signalId, fusion }) =>
        fusion.data === undefined
          ? SpectralAnalysisActions.fusionBytesFetchRejected()
          : SpectralAnalysisActions.fusionNoiseMinBytesFetchRequested({
              signalId,
              contentPath: fusion.data!.noiseArea.minAmplitudes.contentPath,
              freqMin: fusion.data!.noiseArea.freqMin,
              freqMax: fusion.data!.noiseArea.freqMax,
            })
      )
    );
  });

  emitFusionNoiseMaxBytesFetchRequestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.fusionFetched),
      map(({ signalId, fusion }) =>
        fusion.data === undefined
          ? SpectralAnalysisActions.fusionBytesFetchRejected()
          : SpectralAnalysisActions.fusionNoiseMaxBytesFetchRequested({
              signalId,
              contentPath: fusion.data!.noiseArea.maxAmplitudes.contentPath,
              freqMin: fusion.data!.noiseArea.freqMin,
              freqMax: fusion.data!.noiseArea.freqMax,
            })
      )
    );
  });

  tryFetchFusionNoiseMinBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.fusionNoiseMinBytesFetchRequested),
      switchMap(({ signalId, contentPath, freqMin, freqMax }) =>
        this.dataProxyApi
          .getDataBytes(contentPath, fusionBytesStorageDescriptor(`${signalId}_noise_min`, freqMin, freqMax))
          .pipe(
            map(dbRowId => SpectralAnalysisActions.fusionNoiseMinBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? SpectralAnalysisActions.fusionNoiseMinBytesNotFound({ signalId })
                : SpectralAnalysisActions.fusionNoiseMinBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  tryFetchFusionNoiseMaxBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.fusionNoiseMaxBytesFetchRequested),
      switchMap(({ signalId, contentPath, freqMin, freqMax }) =>
        this.dataProxyApi
          .getDataBytes(contentPath, fusionBytesStorageDescriptor(`${signalId}_noise_max`, freqMin, freqMax))
          .pipe(
            map(dbRowId => SpectralAnalysisActions.fusionNoiseMaxBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? SpectralAnalysisActions.fusionNoiseMaxBytesNotFound({ signalId })
                : SpectralAnalysisActions.fusionNoiseMaxBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  emitHarmonicSeriesFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.spectralAnalysisFetched),
      map(({ signalId, spectralAnalysis }) =>
        SpectralAnalysisActions.harmonicSeriesFetched({
          signalId,
          harmonicSeries: spectralAnalysis.harmonicSeries,
        })
      )
    );
  });

  tryFetchHarmonicSeriesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SpectralAnalysisActions.harmonicSeriesFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getHarmonicSeriesData(signalId).pipe(
          map(harmonicSeriesDto =>
            SpectralAnalysisActions.harmonicSeriesFetched({
              signalId,
              harmonicSeries: mapHarmonicSeriesFromDto(harmonicSeriesDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? SpectralAnalysisActions.harmonicSeriesNotFound({ signalId })
              : SpectralAnalysisActions.harmonicSeriesFetchFailed({ signalId });
          })
        )
      )
    );
  });
}
