@let _cursor = cursor();

<app-cursor-item-base
  [cursor]="cursor()"
  #itemBase
  (updated)="updated.emit($event)"
  (deleted)="deleted.emit()"
  (opened)="opened.emit()"
  (closed)="closed.emit()">
  <div cursor-description class="self-center">
    Start: {{ _cursor.start | displayNumber: 'scientific' : 6 : 1e-12 }} ; Interval:
    {{ length() | displayNumber: 'scientific' : 6 : 1e-12 }}
  </div>
  <div cursor-form>
    <mat-form-field class="flex-1">
      <mat-label>Start</mat-label>
      <input type="number" matInput [ngModel]="_cursor.start" (ngModelChange)="updateStart($event)" />
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>End</mat-label>
      <input type="number" matInput [ngModel]="_cursor.end ?? _cursor.start" (ngModelChange)="updateEnd($event)" />
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Interval</mat-label>
      <input type="number" matInput [ngModel]="length()" (ngModelChange)="intervalChange($event)" />
    </mat-form-field>
  </div>
</app-cursor-item-base>
