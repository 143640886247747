import { Injectable } from '@angular/core';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { SpectralAnalysisActions } from '../store/spectral-analysis.actions';
import { spectralAnalysisFeature } from '../store/spectral-analysis.feature';
import { SpectralAnalysisStepCompletedMessage } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class SpectralAnalysisRealtimeRegistrationService {
  constructor(private store: Store) {}

  public registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([
      { messageType: 'CyclesComputed', callback: this.cyclesCompletedHandler },
      { messageType: 'FusionComputed', callback: this.fusionCompletedHandler },
      { messageType: 'HarmonicSeriesComputed', callback: this.harmonicSeriesCompletedHandler },
      { messageType: 'HarmonicSeriesCleared', callback: this.harmonicSeriesCompletedHandler },
    ]);
  }

  private cyclesCompletedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as SpectralAnalysisStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(spectralAnalysisFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    this.store.dispatch(SpectralAnalysisActions.cyclesFetchRequested({ signalId: receivedSignalId }));
  };

  private fusionCompletedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as SpectralAnalysisStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(spectralAnalysisFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    this.store.dispatch(SpectralAnalysisActions.fusionFetchRequested({ signalId: receivedSignalId }));
  };

  private harmonicSeriesCompletedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as SpectralAnalysisStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(spectralAnalysisFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    this.store.dispatch(SpectralAnalysisActions.harmonicSeriesFetchRequested({ signalId: receivedSignalId }));
  };
}
