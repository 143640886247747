import { FusionPeak } from '@features/spectral-analysis/shared/interface/fusion';
import { HarmonicSerie } from '@features/spectral-analysis/shared/interface/harmonic-series';
import { ColoredHarmonicSerie } from '../shared/interfaces';

const harmonicFamiliesColors = [
  '#001219',
  '#005F73',
  '#0A9396',
  '#94D2BD',
  '#E9D8A6',
  '#EE9B00',
  '#CA6702',
  '#BB3E03',
  '#AE2012',
  '#9B2226',
];

export interface HarmonicsSortedBySelectionStatus {
  selected: FusionPeak[];
  unselected: FusionPeak[];
}

export const identifyHarmonics = (harmonicSeries: HarmonicSerie[], peaks: FusionPeak[]) => {
  const seriesPeaks = harmonicSeries
    .map(serie => serie.harmonics)
    .flat()
    .map(harmonic => harmonic.peakIndex);

  const uniquePeakIndices = seriesPeaks.filter((peak, index, peaks) => peaks.indexOf(peak) === index);

  uniquePeakIndices.sort((a, b) => a - b);

  return peaks.filter(peak => uniquePeakIndices.includes(peak.index));
};

export const identifyNonHarmonicPeaks = (harmonicSeriesPeaks: FusionPeak[], peaks: FusionPeak[]) => {
  return peaks.filter(peak => !harmonicSeriesPeaks.includes(peak));
};

export const sortHarmonicsBySelectionStatus = (harmonics: FusionPeak[], selectedHarmonicIndices: number[]) => {
  const harmonicSortedBySelectionStatus: HarmonicsSortedBySelectionStatus = {
    selected: [],
    unselected: [],
  };

  harmonics.reduce((peaks, currentPeak) => {
    if (selectedHarmonicIndices.includes(currentPeak.index)) {
      peaks.selected.push(currentPeak);
    } else {
      peaks.unselected.push(currentPeak);
    }

    return peaks;
  }, harmonicSortedBySelectionStatus);

  return harmonicSortedBySelectionStatus;
};

export const computeSortedSelectedPeakIndices = (selectedSeries: HarmonicSerie[]) => {
  const uniqueSelectedSeriesPeaks = selectedSeries
    .map(serie => serie.harmonics)
    .flat()
    .map(harmonic => harmonic.peakIndex)
    .filter((peak, index, peaks) => peaks.indexOf(peak) === index);

  uniqueSelectedSeriesPeaks.sort();

  return uniqueSelectedSeriesPeaks;
};

export const computeEmphasizedHarmonics = (selectedSeries: ColoredHarmonicSerie[], harmonics: FusionPeak[]) => {
  if (selectedSeries.length < 1) {
    return [];
  }

  const selectedHarmonics = selectedSeries
    .map(serie => {
      const serieHarmonics = serie.harmonics.map(harmonic => {
        // The corresponding peak will necessarily be found here, by definition.
        const correspondingPeak = harmonics.find(peak => peak.index === harmonic.peakIndex)!;

        return {
          ...harmonic,
          frequency: correspondingPeak?.frequency,
          color: serie.color,
        };
      });

      const maxSerieRank = serie.harmonics[serie.harmonics.length - 1].rank;
      const rankList = [...Array(maxSerieRank).keys()].map(index => index + 1);

      const allHarmonics = rankList.map(rank => {
        const correspondingHarmonic = serieHarmonics.find(harmonic => harmonic.rank === rank);

        if (correspondingHarmonic !== undefined) {
          return correspondingHarmonic;
        } else {
          return {
            peakIndex: -1,
            rank,
            frequency: serie.fundamentalFrequency * rank,
            color: serie.color,
          };
        }
      });

      return allHarmonics;
    })
    .flat();

  selectedHarmonics.sort((h1, h2) => h1.frequency - h2.frequency);

  const reduced = selectedHarmonics.slice(1).reduce(
    (harmonics, harmonic) => {
      const latestIndex = harmonics.length - 1;

      if (harmonic.frequency !== harmonics[latestIndex].frequency) {
        harmonics.push(harmonic);
      } else if (harmonic.rank > harmonics[latestIndex].rank) {
        harmonics[latestIndex] = harmonic;
      }

      return harmonics;
    },
    [selectedHarmonics[0]]
  );

  return reduced;
};

export const addColorToHarmonicSeries = (series: HarmonicSerie[]) => {
  return series.map((serie: HarmonicSerie, index: number): ColoredHarmonicSerie => {
    const colorsCount = harmonicFamiliesColors.length;

    return {
      ...serie,
      color: harmonicFamiliesColors[index % colorsCount],
    };
  });
};
